import { Organization } from "@/store/models";
import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import {
  abilitiesPlugin,
  AbilityPluginOptions,
  ABILITY_TOKEN,
  Can,
  useAbility,
} from "@casl/vue";
import { App, InjectionKey, RolesGroup } from "vue";

export type Actions =
  | "create"
  | "read"
  | "update"
  | "delete"
  | "schedule"
  | "publish"
  | "responde"
  | "superadmin";
type PollSubjects = "Poll" | "PollResponse";
type OrganizationSubjects =
  | "Organization"
  | "OrganizationMember"
  | "OrganizationAdmin"
  | "OrganizationAdminsGroup";
type TribeInformationSubjects =
  | "TribeInformation"
  | "TribeInformationSubscribers"
  | "TribeInformationChat"
  | "TribeInformationPublications";
type TribeDiscussionSubjects = "TribeDiscussion" | "TribeDiscussionMembers";
type ProductSubjects =
  | "Visibility"
  | "Pro"
  | "AdminsGroups"
  | "MembersGroups"
  | "AnonymousTribes"
  | "Polls";
export type Subjects =
  | PollSubjects
  | OrganizationSubjects
  | TribeInformationSubjects
  | TribeDiscussionSubjects
  | ProductSubjects;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
export const useAppAbility = (): AppAbility => useAbility<AppAbility>();
export const TOKEN = ABILITY_TOKEN as InjectionKey<AppAbility>;

const globalAbilities: AppAbility = new Ability();

function getRoleStructure(role: string): string[] {
  const structure = role
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? "_" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("")
    .split("_");
  structure.shift();
  return structure;
}

function updateDefaultAbilities(
  subject: Subjects,
  action: Actions | string,
  builder: AbilityBuilder<AppAbility>
): void {
  switch (action) {
    case "list":
      builder.can("read", subject);
      break;
    case "add":
      builder.can("create", subject);
      builder.can("update", subject);
      builder.can("responde", subject);
      break;
    case "create":
    case "read":
    case "update":
    case "delete":
    case "schedule":
    case "publish":
      builder.can(action as Actions, subject);
      break;
    default:
  }
}

function updateOrganizationAbilities(
  orgRoles: string[],
  builder: AbilityBuilder<AppAbility>
): void {
  orgRoles.forEach((role: string) => {
    const structure = getRoleStructure(role);
    switch (structure[0]) {
      case "members":
        updateDefaultAbilities("OrganizationMember", structure[1], builder);
        break;
      case "admin":
        updateDefaultAbilities("OrganizationAdmin", structure[1], builder);
        break;
      case "super":
        if (structure[1] == "admin") {
          builder.can("superadmin", "OrganizationAdmin");
        }
        break;
      default:
        if (structure.length == 1) {
          updateDefaultAbilities("Organization", structure[0], builder);
        }
        break;
    }
  });
}

function updatePublicTribeAbilities(
  publicTribesRoles: string[],
  builder: AbilityBuilder<AppAbility>
): void {
  publicTribesRoles.forEach((role: string) => {
    const structure = getRoleStructure(role);
    switch (structure[0]) {
      case "publication":
        updateDefaultAbilities(
          "TribeInformationPublications",
          structure[1],
          builder
        );
        break;
      case "subscribers":
        updateDefaultAbilities(
          "TribeInformationSubscribers",
          structure[1],
          builder
        );
        break;
      case "chat": {
        updateDefaultAbilities("TribeInformationChat", structure[1], builder);
        break;
      }
      default:
        if (structure.length == 1) {
          updateDefaultAbilities("TribeInformation", structure[0], builder);
        }
        break;
    }
  });
}

function updateDiscussionTribeAbilities(
  discussionTribesRoles: string[],
  builder: AbilityBuilder<AppAbility>
): void {
  discussionTribesRoles.forEach((role: string) => {
    const structure = getRoleStructure(role);
    switch (structure[0]) {
      case "members":
        updateDefaultAbilities("TribeDiscussionMembers", structure[1], builder);
        break;
      default:
        if (structure.length == 1) {
          updateDefaultAbilities("TribeDiscussion", structure[0], builder);
        }
        break;
    }
  });
}

export function updateAbilitiesFromRoles(
  userRoles: Record<string, RolesGroup>
): void {
  const builder = new AbilityBuilder(AppAbility);
  // Clear all rules
  builder.rules.filter(() => false);
  if (userRoles.organizations) {
    updateOrganizationAbilities(userRoles.organizations.roles, builder);
  }

  if (userRoles["public-tribes"]) {
    updatePublicTribeAbilities(userRoles["public-tribes"].roles, builder);
  }

  if (userRoles["discussion-tribes"]) {
    updateDiscussionTribeAbilities(
      userRoles["discussion-tribes"].roles,
      builder
    );
  }

  globalAbilities.update(builder.rules);
}

export function updateAbilitiesFromOrganization(org: Organization): void {
  const builder = new AbilityBuilder(AppAbility);
  if (org.pro) {
    builder.can("read", "Pro");
  }

  if (org.visibility) {
    builder.can("read", "Visibility");
  }

  if (org.adminsGroups) {
    builder.can("read", "AdminsGroups");
  }

  if (org.membersGroups) {
    builder.can("read", "MembersGroups");
  }

  if (org.anonymousTribes) {
    builder.can("read", "AnonymousTribes");
  }

  if (org.polls) {
    builder.can("read", "Polls");
  }

  globalAbilities.update(globalAbilities.rules.concat(builder.rules));
}

export function updateDefaultPollAbilities(): void {
  // TODO manage poll rights
  const builder = new AbilityBuilder(AppAbility);
  builder.can("create", "Poll");
  builder.can("update", "Poll");
  builder.can("read", "Poll");
  builder.can("delete", "Poll");
  globalAbilities.update(globalAbilities.rules.concat(builder.rules));
}

export function getAbilities(): AppAbility {
  return globalAbilities;
}

export default function createAbilities(app: App): void {
  const opts: AbilityPluginOptions = { useGlobalProperties: true };
  abilitiesPlugin(app, globalAbilities, opts);
  app.component(Can.name!, Can);
}
