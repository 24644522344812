import dayjs from "dayjs";
import {
  AbstractPopulatableModel,
  Collection,
  PublicationReaction,
  PublicationSchedule,
} from ".";
import { ObjectLinks } from "@/services/api/objecLinks";

export enum PublicationsStatus {
  Archived = 0,
  Ready = 10,
  ToSchedule = 20,
  Scheduled = 30,
  ToPublish = 40,
  Published = 50,
}

export const PublicationsStatusLabel: Record<number, string> = {
  0: "archived",
  10: "ready",
  20: "to-schedule",
  30: "scheduled",
  40: "to-publish",
  50: "published",
};

type DateLabels = "creationDate" | "updateDate" | "publicationDate";

export default class Publication extends AbstractPopulatableModel {
  id!: string;

  publicTribeId!: string;

  externalId?: string;

  content?: string;

  media?: string | File;

  creationDate?: Date;

  updateDate?: Date;

  publicationDate?: Date;

  status?: PublicationsStatus;

  title?: string;

  hasReactions = false;

  reactions?: Collection<PublicationReaction>;

  schedule?: PublicationSchedule;

  pollId!: string;

  constructor() {
    super();
    this.type = "publication";
  }

  get isArchived(): boolean {
    return this.status === PublicationsStatus.Archived;
  }

  get isReady(): boolean {
    return this.status === PublicationsStatus.Ready;
  }

  get isToSchedule(): boolean {
    return this.status === PublicationsStatus.ToSchedule;
  }

  get isScheduled(): boolean {
    return this.status === PublicationsStatus.Scheduled;
  }

  get isToPublish(): boolean {
    return this.status === PublicationsStatus.ToPublish;
  }

  get isPublished(): boolean {
    return this.status === PublicationsStatus.Published;
  }

  get isPublishable(): boolean {
    return !this.isScheduled && this.isReady;
  }

  get isEditable(): boolean {
    return (
      this.isReady || this.isScheduled || this.isToSchedule || this.isToPublish
    );
  }

  get scheduled(): boolean {
    return this.isToSchedule || this.isScheduled;
  }

  get translatedPublicationDate(): string {
    return this.publicationDate
      ? dayjs(this.publicationDate).format("LLLL")
      : "";
  }

  get statusName(): string {
    let status = this.status ? this.status : -1;
    status = this.status === 0 ? 0 : status;
    return PublicationsStatusLabel[status] || "";
  }

  public populate(data: Record<string, unknown>): void {
    const datesLabel: DateLabels[] = [
      "publicationDate",
      "updateDate",
      "creationDate",
    ];
    datesLabel.forEach((label) => {
      const rawDate = data[label];
      if (rawDate) {
        const date =
          rawDate.constructor === Date ? rawDate : new Date(rawDate as string);
        this[label] = new Date(date);
      }
    });
    if ("_links" in data) {
      const links = data["_links"] as ObjectLinks;

      if ("reactions" in links) {
        this.hasReactions = true;
      }
    }
  }

  public toJSON(toExclude?: string[]): Record<string, unknown> {
    const base = [
      "schedule",
      "status",
      "type",
      "media",
      "creationDate",
      "updateDate",
      "publicationDate",
      "reactions",
      "hasReactions",
    ];
    return super.toJSON([...(toExclude || []), ...base]);
  }
}
