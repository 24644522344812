import { Organization } from "@/store/models";
import {
  AbstractRepository,
  RepositoryFilters,
  SearchRepositoryFilters,
} from "./abstractRepository";
import Poll, { PollStatus } from "@/store/models/poll";
import { AxiosResponse } from "axios";

export default class PollsRepository extends AbstractRepository<Poll> {
  constructor() {
    super({
      subject: "Poll",
      type: "poll",
      subType: "polls",
      TypeConstructor: Poll,
    });
  }

  public download(id: string): Promise<AxiosResponse> {
    if (id === "") {
      return Promise.reject(new Error("Missing id parameter"));
    }
    return this.api.client.get(`${this.endpoint}/${id}/export`, {
      responseType: "blob",
    });
  }
}

export type PollsRepositoryFilters = RepositoryFilters;

export class PollsFilters
  extends SearchRepositoryFilters
  implements PollsRepositoryFilters
{
  states?: PollStatus[];
}
