import {
  Model,
  Organization,
  OrganizationMember,
  OrganizationMembersGroup,
  OrganizationUser,
  OrganizationUsersGroup,
  Poll,
  Publication,
  TribeDiscussion,
  TribeInformation,
  TribeLink,
  TribeMember,
} from "@/store/models";
import { Repository } from "./abstractRepository";
import { SubTribeRepository } from "./abstractSubTribeRepository";
import PollsRepository from "./pollsRepository";
import PollResponsesRepository from "./pollResponsesRepository";
import OrgAdminsRepository from "./orgAdminsRepository";
import OrgAdminsGroupsRepository from "./orgAdminsGroupsRepository";
import OrganizationsRepository from "./organizationsRepository";
import OrgMembersRepository from "./orgMembersRepository";
import OrgMembersGroupsRepository from "./orgMembersGroupsRepository";
import PublicationScheduleRepository from "./publicationScheduleRepository";
import PublicationsRepository from "./publicationsRepository";
import TribeDiscussionMembersRepository from "./tribeDiscussionMembersRepository";
import TribeLinksRepository from "./tribeLinksRepository";
import TribeMembersRepository from "./tribeMembersRepository";
import TribesDiscussionRepository from "./tribesDiscussionRepository";
import TribesInformationRepository from "./tribesInformationRepository";
import PublicationReactionsRepository from "./publicationReactionsRepository";
import MemberSubscriptionsRepository from "./memberSubscriptionsRepository";
import PollResponse from "@/store/models/pollResponse";

type availableOrganizationUsersRepositories =
  | "orgMembers"
  | "orgMember"
  | "orgMembersGroups"
  | "orgMembersGroup"
  | "orgAdmins"
  | "orgRequests"
  | "orgAdminsGroups"
  | "orgAdminsGroup"
  | "orgAdmin"
  | "membersSubscriptions";
type availableTribeRepositories =
  | "publications"
  | "publication"
  | "members"
  | "member"
  | "discussionMembers"
  | "discussionMember"
  | "schedule"
  | "tribeLinks";
export type availableRepositories =
  | "tribes"
  | "tribe"
  | "information"
  | "discussion"
  | "discussions"
  | "organizations"
  | "organization"
  | "publicationReactions"
  | "polls"
  | "pollResponses"
  | availableOrganizationUsersRepositories
  | availableTribeRepositories;

const repos: Record<string, unknown> = {};

function getRepositoryInstance<M extends Model>(
  name: availableRepositories,
  c: new () => Repository<M>
): Repository<M> {
  if (repos[name] === undefined) {
    repos[name] = new c();
  }
  return repos[name] as Repository<M>;
}

export function getOrganizationsRepository(): OrganizationsRepository {
  return getRepositoryInstance(
    "organizations",
    OrganizationsRepository
  ) as OrganizationsRepository;
}

export function getOrgMembersRepository(): OrgMembersRepository {
  return getRepositoryInstance(
    "orgMembers",
    OrgMembersRepository
  ) as OrgMembersRepository;
}

export function getOrgMembersGroupsRepository(): OrgMembersGroupsRepository {
  return getRepositoryInstance(
    "orgMembersGroups",
    OrgMembersGroupsRepository
  ) as OrgMembersGroupsRepository;
}

export function getOrgAdminsRepository(): OrgAdminsRepository {
  return getRepositoryInstance(
    "orgAdmins",
    OrgAdminsRepository
  ) as OrgAdminsRepository;
}

export function getOrgAdminsGroupsRepository(): OrgAdminsGroupsRepository {
  return getRepositoryInstance(
    "orgAdminsGroups",
    OrgAdminsGroupsRepository
  ) as OrgAdminsGroupsRepository;
}

export function getPollsRepository(): PollsRepository {
  return getRepositoryInstance("polls", PollsRepository) as PollsRepository;
}

export function getPollResponsesRepository(): PollResponsesRepository {
  return getRepositoryInstance(
    "pollResponses",
    PollResponsesRepository
  ) as PollResponsesRepository;
}

export function getTribesInformationRepository(): TribesInformationRepository {
  return getRepositoryInstance(
    "tribes",
    TribesInformationRepository
  ) as TribesInformationRepository;
}

export function getPublicationsRepository(): PublicationsRepository {
  return getRepositoryInstance(
    "publications",
    PublicationsRepository
  ) as PublicationsRepository;
}

export function getScheduleRepository(): PublicationScheduleRepository {
  return getRepositoryInstance(
    "schedule",
    PublicationScheduleRepository
  ) as PublicationScheduleRepository;
}

export function getTribeMembersRepository(): TribeMembersRepository {
  return getRepositoryInstance(
    "members",
    TribeMembersRepository
  ) as TribeMembersRepository;
}

export function getTribesDiscussionRepository(): TribesDiscussionRepository {
  return getRepositoryInstance(
    "discussions",
    TribesDiscussionRepository
  ) as TribesDiscussionRepository;
}

export function getTribeDiscussionMembersRepository(): TribeDiscussionMembersRepository {
  return getRepositoryInstance(
    "discussionMembers",
    TribeDiscussionMembersRepository
  ) as TribeDiscussionMembersRepository;
}

export function getTribeLinksRepository(): TribeLinksRepository {
  return getRepositoryInstance(
    "tribeLinks",
    TribeLinksRepository
  ) as TribeLinksRepository;
}

export function getPublicationReactionsRepository(): PublicationReactionsRepository {
  return getRepositoryInstance(
    "publicationReactions",
    PublicationReactionsRepository
  ) as PublicationReactionsRepository;
}

export function getMemberSubscriptionsRepository(): MemberSubscriptionsRepository {
  return getRepositoryInstance(
    "membersSubscriptions",
    MemberSubscriptionsRepository
  ) as MemberSubscriptionsRepository;
}

export function getRepository(
  name: availableRepositories
):
  | Repository<TribeMember>
  | Repository<TribeInformation>
  | Repository<TribeDiscussion>
  | Repository<Publication>
  | Repository<Organization>
  | Repository<OrganizationUser>
  | Repository<OrganizationUsersGroup>
  | Repository<OrganizationMember>
  | Repository<OrganizationMembersGroup>
  | Repository<TribeLink>
  | Repository<OrganizationMembersGroup>
  | Repository<Poll>
  | Repository<PollResponse> {
  switch (name) {
    case "tribes":
    case "tribe":
    case "information":
      return getTribesInformationRepository();
    case "discussions":
    case "discussion":
      return getTribesDiscussionRepository();
    case "polls":
      return getPollsRepository();
    case "pollResponses":
      return getPollResponsesRepository();
    case "organizations":
    case "organization":
      return getOrganizationsRepository();
    case "orgAdmins":
    case "orgAdmin":
      return getOrgAdminsRepository();
    case "orgAdminsGroups":
    case "orgAdminsGroup":
      return getOrgAdminsGroupsRepository();
    case "orgMembers":
    case "orgMember":
      return getOrgMembersRepository();
    case "orgMembersGroups":
    case "orgMembersGroup":
      return getOrgMembersGroupsRepository();
    default:
      try {
        return getTribeRepository(name as availableTribeRepositories);
      } catch (e) {
        throw new Error(`'${name}' repository not exist`);
      }
  }
}

export function getTribeRepository(
  name: availableTribeRepositories
):
  | SubTribeRepository<Publication>
  | SubTribeRepository<TribeMember>
  | SubTribeRepository<TribeLink> {
  switch (name) {
    case "publications":
    case "publication":
      return getPublicationsRepository();
    case "members":
    case "member":
      return getTribeMembersRepository();
    case "tribeLinks":
      return getTribeLinksRepository();
    default:
      throw new Error(`'${name}' not found or not SubTribe repository`);
  }
}
