
import { AuthenticationMixin } from "@/store/mixins";
import { mixins, Options } from "vue-class-component";
import { Watch } from "vue-property-decorator";

declare const APP_VERSION: string;

@Options({})
export default class Navbar extends mixins(AuthenticationMixin) {
  @Watch("$route")
  onRouteChange(): void {
    const collapseBtn: HTMLButtonElement = this.$el.querySelector("button");
    if (!collapseBtn.classList.contains("collapsed")) {
      collapseBtn.click();
    }
  }

  get appVersion(): string {
    return `Version ${APP_VERSION}`;
  }

  get smsCredits(): number | undefined {
    return this.$store.state.user?.organizations[0].smsCredits;
  }

  get userAdminGroup(): string | undefined {
    return this.$store.state.user?.adminGroup;
  }

  get canManagePolls(): boolean {
    // only reserved to root admin user and for org that have the options
    return this.$can("read", "Polls") && !this.userAdminGroup;
  }
}
