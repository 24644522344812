import { AbstractModel, Collection } from ".";
import PollQuestion from "./pollQuestion";
import Position from "./position";
import Slot from "./slot";

export enum PollStatus {
  Archived = 0,
  Created = 10,
  Published = 20,
  Closed = 30,
}

export default class Poll extends AbstractModel {
  type = "poll";
  id?: string;
  title?: string;
  state?: PollStatus;
  questions?: PollQuestion[];
  voters?: number;

  /*
  // eslint-disable-next-line
  public toJSON(toExclude?: string[]): Record<string, any> {
    return super.toJSON(["logo", "image"]);
  }
    */
}
