import { InjectionKey } from "vue";
import { State } from "vue";
import { createStore, Store } from "vuex";
// import { createPersistedPlugin } from "./persistance";
import {
  createExternalStoreModule,
  createInternalStoreModule,
  createOrganizationModule,
  createUserStoreModule,
  createEventsModule,
  createFeaturesModule,
  createPollsModule,
} from "./modules";
import createChatModule from "./modules/chats";

export const key: InjectionKey<Store<State>> = Symbol();

// TODO: Remove logger for production
export const store = createStore<State>({
  state: {
    ready: false,
  },
  modules: {
    features: createFeaturesModule(),
    user: createUserStoreModule(),
    externals: createExternalStoreModule(),
    internals: createInternalStoreModule(),
    organization: createOrganizationModule(),
    events: createEventsModule(),
    chat: createChatModule(),
    polls: createPollsModule(),
  },
  mutations: {
    isReady(state: State, payload: boolean) {
      state.ready = payload;
    },
  },
  actions: {
    checkLoadStep({ commit, state }) {
      if (
        state.user?.organizations === undefined ||
        state.user?.organizations.length < 1
      ) {
        return;
      }
      if (
        state.features?.loaded === undefined ||
        state.features.loaded === false
      ) {
        return;
      }
      commit("isReady", true);
    },
  },
  plugins: [
    // createLogger(),
    // createPersistedPlugin(),
  ],
  strict: false,
});

export function useStore(): Store<State> {
  return store;
}
