import { getTribesInformationRepository } from "@/services/repositories";
import {
  Collection,
  OrganizationUsersGroup,
  TribeInformation,
  TribeTypes,
} from "@/store/models";
import { reactive } from "vue";
import { mixins, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  fileChangeEvent,
  FormFieldFileOptions,
  FormFields,
  FormMode,
  FormSteps,
  validateFormStep,
} from "./forms";
import { ToastMixin } from "./toast";
import { SearchRepositoryFilters } from "@/services/repositories/abstractRepository";

export type TribeInformationType = "internal" | "external";
export type TribeInformationState = "internals" | "externals";
export function getTribeStateByType(
  mode: TribeInformationType
): TribeInformationState {
  switch (mode) {
    case "internal":
      return "internals";
    case "external":
      return "externals";
    default:
      throw new Error(`Mode '${mode}' is not supported`);
  }
}

export class TribeModeMixin extends Vue {
  get isRootRoute(): boolean {
    return (
      this.$route.path === "/externals" ||
      this.$route.path === "/internals" ||
      this.$route.path === "/organization" ||
      this.$route.path === "/polls"
    );
  }

  get modeState(): TribeInformationState | undefined {
    if (this.mode) {
      return getTribeStateByType(this.mode);
    }
    return undefined;
  }

  get tribeURLId(): string {
    return (this.$route.params.id as string) || "";
  }

  get modeRouteName(): string {
    let name = "";
    if (this.mode) {
      const capital = this.mode.charAt(0).toUpperCase();
      name = `${capital}${this.mode.slice(1)}`;
    }
    return name;
  }

  get mode(): TribeInformationType | undefined {
    if (this.$route.path.startsWith("/externals")) {
      return "external";
    }
    if (this.$route.path.startsWith("/internals")) {
      return "internal";
    }
    return undefined;
  }

  get externalMode(): boolean {
    return this.mode === "external";
  }

  get internalMode(): boolean {
    return this.mode === "internal";
  }

  get type(): TribeTypes {
    if (this.modeState) {
      return (
        this.$store.state[this.modeState]?.tribeType || TribeTypes.Information
      );
    }
    return TribeTypes.Information;
  }

  get isInformation(): boolean {
    return this.type === TribeTypes.Information;
  }

  get isDiscussion(): boolean {
    return this.type === TribeTypes.Discussion;
  }

  get loading(): boolean {
    let isLoading: boolean | undefined;
    if (this.modeState) {
      isLoading = this.$store.state[this.modeState]?.loadingTribes;
    }
    return isLoading !== undefined ? isLoading : true;
  }

  get hasTribes(): boolean {
    if (this.modeState) {
      const tribes = this.$store.state[this.modeState]
        ?.tribes as Collection<unknown>;
      return tribes !== undefined && tribes.length > 0;
    }
    return false;
  }

  get adminsGroups(): Collection<OrganizationUsersGroup> | undefined {
    return this.$store.state.organization?.adminsGroups;
  }

  get hasAdminsGroups(): boolean {
    return this.adminsGroups ? this.adminsGroups.length > 0 : false;
  }

  get adminsGroupsContent_(): OrganizationUsersGroup[] {
    return this.adminsGroups?.entries || [];
  }

  get adminsGroupsContent(): Record<string, string> {
    const groups: Record<string, string> = {};

    // Here there is the problem that it may have more groups ?
    // so load all groups (not good... but works)
    const filters = new SearchRepositoryFilters();
    filters.size = 1000;

    if (this.$can("read", "AdminsGroups") && !this.userAdminGroup) {
      Promise.resolve(
        this.$store.dispatch("organization/fetchAdminsGroups", filters)
      ).then(() => {
        if (this.adminsGroups !== undefined) {
          for (const item of this.adminsGroups?.entries) {
            groups[item.id ?? ""] = item.name ?? "";
          }
        }
        // cause get groups is asynchrone, force refresh the compenent
        // to get new values
        this.$forceUpdate();
      });
    }

    return groups;
  }

  get userAdminGroup(): string | undefined {
    return this.$store.state.user?.adminGroup;
  }
}

export class TribeInformationFormMixin extends mixins(
  ToastMixin,
  TribeModeMixin
) {
  isSaving = false;
  isLoading = false;
  pStep = 0;
  pForm: FormSteps = {};
  tribe?: TribeInformation;
  error: Error[] = [];
  formMode: FormMode = "create";

  @Prop({})
  id = "";

  get currentStep(): number {
    return this.pStep;
  }

  get hasPrevious(): boolean {
    return !(this.pStep > 0);
  }

  get hasNext(): boolean {
    return this.pStep < Object.keys(this.pForm).length - 1;
  }

  get form(): FormSteps {
    if (Object.keys(this.pForm).length > 0) {
      return this.pForm;
    }
    const step1: FormFields = {
      name: { type: "text", required: true },
      description: { type: "long-text", limit: 1000 },
      privateResponse: { type: "checkbox", model: "privateMessagingEnabled" },
    };
    if (this.$ability.can("read", "AnonymousTribes")) {
      step1["anonymous"] = {
        type: "checkbox",
        model: "anonymous",
        disabled: (object) => {
          return (<TribeInformation>object)?.anonymous == true || false;
        },
      };
    }
    step1["searchable"] = { type: "checkbox", model: "isSearchable" };
    if (
      this.$ability.can("read", "AdminsGroups") &&
      !this.$store.state.user?.adminGroup
    ) {
      step1["adminGroup"] = {
        type: "single-select",
        data: this.adminsGroupsContent,
      };
    }

    const step2: FormFields = {
      logo: {
        type: "picture",
        sizeLimit: "100ko",
        form: "square",
        maxHeight: 300,
        changeCallbackFn: (file) => {
          if (this.tribe) {
            this.tribe.logo = file;
          }
        },
      },
      image: {
        type: "picture",
        model: "image",
        sizeLimit: "700ko",
        maxHeight: 450,
        maxWidth: 800,
        changeCallbackFn: (file) => {
          if (this.tribe) {
            this.tribe.image = file;
          }
        },
      },
      welcome: { type: "long-text", limit: 1000 },
    };

    this.pForm = { step1, step2 };

    if (this.externalMode) {
      this.pForm["step3"] = {
        address: { type: "text" },
        postalCode: { type: "text" },
        city: { type: "text" },
        country: {
          type: "select",
          data: this.$countries.getNames(),
        },
        // timeSlot: { type: "time-slot" },
      };
    }

    return this.pForm;
  }

  getTranslateName(component: string): string {
    return `tribes-information.form.${this.mode}.${component}`;
  }

  goToStep(step: number): void {
    if (this.checkStep()) {
      this.pStep = step;
    }
  }

  goNextStep(): void {
    if (this.checkStep()) {
      this.pStep += 1;
    }
  }

  goPreviousStep(): void {
    if (this.checkStep()) {
      this.pStep -= 1;
    }
  }

  get hasError(): boolean {
    return this.error.length > 0;
  }

  checkStep(): boolean {
    return validateFormStep(this.form, this.currentStep, this.tribe, this.id);
  }

  fileChange(evt: Event, options: FormFieldFileOptions): void {
    if (this.isSaving) {
      return;
    }
    const { id, value } = fileChangeEvent(evt, options);
    this.tribe?.set(id, value);
  }

  clearForm(): void {
    this.isLoading = false;
    this.isSaving = false;
    this.pStep = 0;
    this.error = [];
  }

  setTribe(tribe: TribeInformation): TribeInformation {
    this.tribe = reactive(tribe);
    if (this.tribe.country) {
      this.tribe.country = this.$countries.getName(this.tribe.country);
    }

    if (
      this.$ability.can("read", "AdminsGroups") &&
      !this.$store.state.user?.adminGroup
    ) {
      if (this.tribe.adminGroup === undefined) {
        this.tribe.adminGroup = "";
      }
    }
    return this.tribe;
  }

  save(): Promise<void | TribeInformation> {
    this.isSaving = true;
    if (
      this.tribe &&
      validateFormStep(this.form, this.currentStep, this.tribe, this.id)
    ) {
      if (this.tribe.country) {
        this.tribe.country = this.$countries.getAlpha2Code(this.tribe.country);
      }

      if (this.tribe.privateMessagingEnabled === undefined) {
        this.tribe.privateMessagingEnabled = false;
      }

      if (this.tribe.anonymous === undefined) {
        this.tribe.anonymous = false;
      }

      if (this.tribe.isSearchable === undefined) {
        this.tribe.isSearchable = false;
      }

      if (this.tribe.organizationId === undefined) {
        this.tribe.organizationId =
          this.$store.state.user?.organizations[0].id || "";
      }

      if (this.tribe.isExternal === undefined) {
        this.tribe.isExternal = this.externalMode;
      }

      if (this.tribe.adminGroup === "") {
        this.tribe.adminGroup = null;
      }

      return getTribesInformationRepository()
        .save(this.tribe)
        .then((model) => {
          if (this.modeState) {
            this.addSuccessEvent(
              this.$t(
                `tribes-information.form.${this.mode}.${this.formMode}.prompts.title`
              ),
              this.$t(
                `tribes-information.form.${this.mode}.${this.formMode}.prompts.success`,
                { ...model }
              )
            );
            this.$router.push({
              name: `${this.modeRouteName}TribeInformationPublications`,
              params: {
                id: model.id,
              },
            });
            this.$store.dispatch(`${this.modeState}/fetchTribes`);
          }
          return model;
        })
        .catch((reason) => {
          this.addErrorEvent(
            this.$t(
              `tribes-information.form.${this.mode}.${this.formMode}.prompts.title`
            ),
            this.$t(
              `tribes-information.form.${this.mode}.${this.formMode}.prompts.error`,
              { ...this.tribe }
            )
          );
          throw reason;
        })
        .finally(() => {
          this.clearForm();
        });
    }
    this.isSaving = false;
    return Promise.resolve();
  }
}
