import { store } from "@/store";
import { FeatureKnown } from "@/store/modules/features";
import { StandardEventType } from "../event";
import { ChatRoom } from "../models";
import { IMinimalEvent } from "../types";
import Parser from "./types";

export default class EphemeralParser implements Parser {
  constructor(public room: ChatRoom) {}

  parse(event: IMinimalEvent): Promise<EphemeralParser> {
    // console.log("EphemeralParter", `Parse room ${this.room.id}`, event);
    const featRead = store.getters["features/enabled"](
      FeatureKnown.oneToOneIsReadBy
    );
    switch (event.type) {
      // case StandardEventType.Typing:
      //   break;
      case StandardEventType.Receipt:
        if (featRead) {
          Object.keys(event.content).forEach((eventId) => {
            if (this.room.readStatus === undefined) {
              this.room.readStatus = {};
            }
            this.room.readStatus[eventId] = Object.keys(
              event.content[eventId]["m.read"]
            );
          });
        }
        break;
      default:
    }
    return Promise.resolve(this);
  }
}
