import dayjs from "dayjs";
import { AbstractModel } from "./abstractModel";

export default class PollResponse extends AbstractModel {
  type = "pollresponse";
  userId?: string;
  displayName?: string;
  date?: Date;

  get translatedDate(): string {
    return this.date ? dayjs(this.date).format("LLLL") : "";
  }
}
