import {
  Collection,
  OrganizationUser,
  OrganizationUsersGroup,
  Poll,
} from "@/store/models";
import AbstractSubOrgRepository from "./abstractSubOrgRepository";
import PollResponse from "@/store/models/pollResponse";
import PollChoice from "@/store/models/pollChoice";
import { AbstractRepository, RepositoryFilters } from "./abstractRepository";

export default class PollResponsesRepository extends AbstractRepository<PollResponse> {
  protected choiceId?: string;
  protected pollId?: string;

  constructor() {
    super({
      subject: "PollResponse",
      type: "poll",
      subType: "responses",
      endpoint: "{pollId}/responses/{choiceId}",
      TypeConstructor: PollResponse,
    });
  }

  public setChoice(pollChoice?: PollChoice | string): PollResponsesRepository {
    this.choiceId =
      typeof pollChoice === "string" ? pollChoice : pollChoice?.id || "";
    return this;
  }

  public setPoll(poll?: Poll | string): PollResponsesRepository {
    this.pollId = typeof poll === "string" ? poll : poll?.id || "";
    return this;
  }
}
