import { getPollsRepository } from "@/services/repositories";
import { RepositoryFilters } from "@/services/repositories/abstractRepository";
import { PollsState, State } from "vue";
import { Action, Module, MutationTree } from "vuex";
import { Collection, Poll } from "../models";
import {
  PollsFilters,
  PollsRepositoryFilters,
} from "@/services/repositories/pollsRepository";

function generateStates(): PollsState {
  return {
    loadingPolls: false,
  };
}

function generateMutations(): MutationTree<PollsState> {
  return {
    // TODO
    setPollsFilters(state: PollsState, filters: PollsRepositoryFilters) {
      state.pollsFilters = filters;
    },
    clearPollsFilters(state: PollsState) {
      state.pollsFilters = undefined;
    },
    setFetchedPolls(state: PollsState, collection: Collection<Poll>) {
      state.polls = collection;
    },
    nextPolls(state: PollsState) {
      state.polls?.loadNext();
    },
    loadingPolls(state: PollsState) {
      state.loadingPolls = true;
    },
    pollsLoaded(state: PollsState) {
      state.loadingPolls = false;
    },
    setPoll(state: PollsState, poll: Poll) {
      state.selectorPoll = poll;
      if (!poll) {
        state.selectorPollId = undefined;
      } else {
        state.selectorPollId = poll.id;
      }
    },
  };
}

function generateActions(): Record<string, Action<PollsState, State>> {
  return {
    changePollsFilters(
      { commit, dispatch },
      payload?: {
        search?: string;
        groupIds?: string[];
        descendant?: boolean;
      }
    ) {
      if (
        !payload ||
        (!payload.search &&
          !payload.groupIds &&
          payload.descendant === undefined)
      ) {
        commit("clearPollsFilters");
        return dispatch("fetchPolls");
      }
      const filters = new PollsFilters();
      /*
      if (payload.search && payload.search.trim().length > 0) {
        filters.search = payload.search;
      }
      if (payload.groupIds && payload.groupIds.length > 0) {
        filters.groupIds = payload.groupIds;
      }
      if (payload.descendant !== undefined) {
        filters.descendant = payload.descendant;
      }
        */
      commit("setPollsFilters", filters);
      dispatch("fetchPolls", filters);
    },
    fetchPollsFilters({ commit, dispatch, rootState }) {
      commit("loadingPolls");
      dispatch("fetchPolls", rootState?.organization?.adminsFilters);
    },
    fetchPolls({ commit }, payload?: RepositoryFilters) {
      commit("loadingPolls");
      return getPollsRepository()
        .find(payload)
        .then((collection) => {
          commit("setFetchedPolls", collection);
        })
        .finally(() => {
          commit("pollsLoaded");
        });
    },
    setPoll({ commit, dispatch, rootState }, poll?: Poll) {
      // getPollsRepository();
      commit("setPoll", poll);
    },
    fetchNextPolls({ state }) {
      if (state.polls === undefined) {
        return Promise.resolve();
      }
      return state.polls.loadNext();
    },
  };
}

export default function createPollsModule(): Module<PollsState, State> {
  return {
    namespaced: true,
    state: generateStates(),
    mutations: generateMutations(),
    actions: generateActions(),
  };
}
