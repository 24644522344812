import { Module } from "vuex";
import { AllowedPublicTribe, Profile, RolesGroup, State, UserState } from "vue";
import { User } from "oidc-client-ts";
import hash from "object-hash";
import {
  updateAbilitiesFromOrganization,
  updateAbilitiesFromRoles,
  updateDefaultPollAbilities,
} from "@/plugins/casl";
import { Organization, ToastEvent } from "../models";
import {
  getOrgAdminsGroupsRepository,
  getOrgMembersGroupsRepository,
  getOrgAdminsRepository,
  getOrganizationsRepository,
  getOrgMembersRepository,
} from "@/services/repositories";
import { store } from "..";
import dayjs from "dayjs";

let previousFingerprint: string;

// eslint-disable-next-line
function parseJwt(token: string): any {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  return JSON.parse(jsonPayload);
}

class UserStruct {
  authenticated = false;
  profile: Profile = {};
  organizations: Organization[] = [];
  publicTribes: AllowedPublicTribe[] = [];
  roles: Record<string, RolesGroup> = {};

  // eslint-disable-next-line
  private decodedToken: any;

  constructor(token?: string) {
    if (token) {
      this.authenticated = true;
      this.decodedToken = parseJwt(token);
      this.calculateProfile();
      this.calculateOrganizations();
      this.calculatePublicTribes();
      this.calculateRolesGroup();
    }
  }

  applyToState(state: UserState): void {
    state.authenticated = this.authenticated;
    state.profile = this.profile;
    state.organizations = this.organizations;
    state.publicTribes = this.publicTribes;
    state.roles = this.roles;
    state.adminGroup = this.organizations[0]?.adminGroup;
  }

  getHash(): string {
    return hash({
      profile: this.profile,
      organizations: this.organizations,
      publicTribes: this.publicTribes,
      roles: this.roles,
    });
  }

  hasChanged(): boolean {
    return this.getHash() !== previousFingerprint;
  }

  private calculateProfile(): void {
    this.profile.name = this.decodedToken.name;
    this.profile.email = this.decodedToken.email;
  }

  private calculateOrganizations(): void {
    const rawOrgs: Partial<Organization>[] = this.decodedToken.organizations;
    if (this.decodedToken.organizations) {
      rawOrgs.forEach((val: Partial<Organization>) => {
        const org: Organization = Object.assign(new Organization(), val);
        this.organizations.push(org);
      });
    }
  }

  private calculatePublicTribes(): void {
    const rawTribes: { id: string }[] = this.decodedToken.publicTribes?.allowed;
    if (rawTribes) {
      rawTribes.forEach((val: { id: string }) => {
        this.publicTribes.push(val);
      });
    }
  }

  private calculateRolesGroup(): void {
    const rawGroups: Record<string, { roles: string[] }> =
      this.decodedToken.resource_access;
    if (rawGroups) {
      Object.entries(rawGroups).forEach((val) => {
        this.roles[val[0]] = {
          name: val[0],
          roles: val[1].roles,
        };
      });
    }
  }
}

class LockedOrganizationError extends Error {
  type = "LockedOrganizationError";
  constructor(organization: Organization) {
    super(`Organization ${organization.name} is locked`);
  }
}

export default function createUserStoreModule(): Module<UserState, State> {
  return {
    namespaced: true,
    state: {
      authenticated: false,
      profile: {},
      organizations: [],
      publicTribes: [],
      roles: {},
    },
    getters: {},
    mutations: {
      set(state: UserState, usr: UserStruct): void {
        usr.applyToState(state);
        if (state.organizations[0]) {
          const org = state.organizations[0];
          getOrgMembersRepository().setOrg(org);
          getOrgAdminsRepository().setOrg(org);
          getOrgAdminsGroupsRepository().setOrg(org);
          getOrgMembersGroupsRepository().setOrg(org);
        }
      },
      setOrganizations(state: UserState, orgs: Organization[]): void {
        state.organizations = orgs;
      },
      remove(state: UserState): void {
        new UserStruct().applyToState(state);
      },
    },
    actions: {
      set({ commit, state }, oidcUser: User) {
        if (oidcUser === null) {
          return commit("remove");
        }
        const usr = new UserStruct(oidcUser.access_token);
        if (usr.hasChanged()) {
          // console.log("[IP-USER] - User changed")
          previousFingerprint = usr.getHash();

          const orgsPromise: Promise<Organization>[] = [];
          usr.organizations.forEach((org: Partial<Organization>) => {
            orgsPromise.push(
              getOrganizationsRepository().getById(org.id || "")
            );
          });

          return Promise.all(orgsPromise)
            .then((orgs: Organization[]) => {
              commit("set", usr);
              commit("setOrganizations", orgs);
              updateAbilitiesFromRoles(usr.roles);
              updateAbilitiesFromOrganization(orgs[0]);
              updateDefaultPollAbilities();
              store.dispatch("checkLoadStep");
            })
            .then(() => {
              if (state.organizations[0]) {
                const org = state.organizations[0];
                if (org.locked) {
                  throw new LockedOrganizationError(org);
                }
                if (org.lockedDate) {
                  commit(
                    "events/add",
                    new ToastEvent()
                      .setToTranslate()
                      .setLevel("warning")
                      .setTitle("organization.prompts.limitedTitle")
                      .setContent({
                        type: "text",
                        data: {
                          text: "organization.prompts.limitedContent",
                          textContent: {
                            date: dayjs(org.lockedDate).format("LLLL"),
                          },
                        },
                      }),
                    { root: true }
                  );
                }
              }
            })
            .then(() => {
              commit(
                "organization/setAdminsGroup",
                usr.organizations[0].adminGroup,
                { root: true }
              );
            });
        }
        // console.log("[IP-USER] - User not changed")
      },
    },
  };
}
